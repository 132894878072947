var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userlist
    ? _c(
        "div",
        {
          staticClass: "basic_work_team_avatar",
          class: [`num${_vm.userlist.length}`],
        },
        [
          _vm._l(_vm.userlist, function (item, index) {
            return _c("img", {
              key: index,
              staticClass: "img",
              attrs: { src: `${_vm.$avatar_url}${item}`, alt: "" },
            })
          }),
          _c("div", { staticClass: "icon" }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }