<!--
 * @Author       : Hugo
 * @Date         : 2020-10-13 13:39:23
 * @LastEditTime : 2020-12-24 14:02:33
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/basic/workTeamAvatar.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div class="basic_work_team_avatar" :class="[`num${userlist.length}`]" v-if="userlist">
    <img
      :src="`${$avatar_url}${item}`"
      alt
      class="img"
      v-for="(item,index) in userlist"
      :key="index"
    />
    <!-- <img src="https://tse3-mm.cn.bing.net/th/id/OIP.4tQRVuvWoRSgJ4_atKsmQQAAAA?pid=Api&rs=1" alt="" class="img"> -->
    <!-- <img src="https://tse3-mm.cn.bing.net/th/id/OIP.4tQRVuvWoRSgJ4_atKsmQQAAAA?pid=Api&rs=1" alt="" class="img"> -->
    <!-- <img src="https://tse3-mm.cn.bing.net/th/id/OIP.4tQRVuvWoRSgJ4_atKsmQQAAAA?pid=Api&rs=1" alt="" class="img"> -->
    <div class="icon"></div>
  </div>
</template>
<script>
export default {
  props: {
    userlist: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.basic_work_team_avatar {
  @include flexsb;
  @include bbox;
  align-content: space-between;
  // padding: 1px;
  // padding-right: 1px;
  flex-wrap: wrap;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  z-index: 2;
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: #bbb;
  $group_width: 44-3px;
  .img {
    @include bbox;
    display: block;
    // border:1px solid transparent;
  }
  &.num1{
    .img{
      width: 44px;
      height: 44px;
    }
  }
  &.num2,
  &.num3,
  &.num4 {
    .img {
      width: $group_width/2;
      height: $group_width/2;
    }
  }
  &.num2 {
    align-content: center;
  }
  &.num3 {
    .img {
      &:nth-of-type(1) {
        margin-left: $group_width/4;
      }
      &:nth-of-type(3) {
        border-bottom-left-radius: 4px;
      }
      &:nth-of-type(4) {
        border-bottom-right-radius: 4px;
      }
    }
  }
  &.num4 {
    // $group_width: 44-3px;
    .img {
      &:nth-of-type(1) {
        border-top-left-radius: 4px;
      }
      &:nth-of-type(2) {
        border-top-right-radius: 4px;
      }
      &:nth-of-type(3) {
        border-bottom-left-radius: 4px;
      }
      &:nth-of-type(4) {
        border-bottom-right-radius: 4px;
      }
    }
  }
  &.num5,
  &.num6,
  &.num7,
  &.num8,
  &.num9 {
    .img {
      width: $group_width/3;
      height: $group_width/3;
    }
  }
  &.num5,
  &.num6 {
    align-content: center;
  }
  &.num5 {
    .img {
      &:nth-of-type(1) {
        margin-left: $group_width/6;
      }
      &:nth-of-type(2) {
        margin-right: $group_width/6 + 1px;
      }
    }
  }
  &.num7 {
    .img {
      &:nth-of-type(1) {
        margin: {
          left: $group_width/3;
          right: $group_width/3;
        }
      }
      &:nth-of-type(5) {
        border-bottom-left-radius: 4px;
      }
      &:nth-of-type(7) {
        border-bottom-right-radius: 4px;
      }
    }
  }
  &.num8 {
    .img {
      &:nth-of-type(1) {
        margin-left: $group_width/6;
      }
      &:nth-of-type(2) {
        margin-right: $group_width/6 + 1px;
      }
      &:nth-of-type(6) {
        border-bottom-left-radius: 4px;
      }
      &:nth-of-type(8) {
        border-bottom-right-radius: 4px;
      }
    }
  }
  &.num9 {
    // $group_width: 44-3px;
    .img {
      &:nth-of-type(1) {
        border-top-left-radius: 4px;
      }
      &:nth-of-type(3) {
        border-top-right-radius: 4px;
      }
      &:nth-of-type(7) {
        border-bottom-left-radius: 4px;
      }
      &:nth-of-type(9) {
        border-bottom-right-radius: 4px;
      }
    }
  }
}
</style>
